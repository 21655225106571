import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  LWrap,
  RecruitNews,
  Vertical,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
  ContainerCrumbs,
  LWrap2
} from "../../../../components/_index";
import { recruitArea } from "../../../../utils/recruit";
import "../../../../assets/_sass/page/recruit/common.scss";
// markup

const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "お知らせ",
    content: "Information",
    cla_left: "cla_left",
    cla_right: "cla_right",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "お知らせ",
      src: "/recruit/news/",
    },
    {
      title: "イベント出展のお知らせ",
      src: "/recruit/news/article01/",
    },
  ];
  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false}/>
      <section className="l_sect04  l_sect04_sp recruit-font">
        <LWrap2>
        <div className="new_main">
          <ContainerCrumbs data={crumbsData} />
          <div className="container_main container_main_1280">
            <Vertical data={verticalData} />
            <div className="container_right">
              <h2 className="content-title">イベント出展のお知らせ</h2>
              <p className="sub-title">
                <span className="sub-title-left">2025.01.30</span>
                <span className="sub-title-right">イベント</span>
              </p>

              <p className="content-detail">
                国内最大級のホテル業界就活イベントへ出展いたします（東京・大阪・福岡）。
                <br className="" />
                リンクよりお申し込みのうえご参加ください！
                <br className="" />
                <Link
                className="hover_active"
                  to="https://hoteres-shinsotsu.com/contents/gousetsu"
                  target="_blank"
                >
                  https://hoteres-shinsotsu.com/contents/gousetsu
                  <i className="icon-blank"></i>
                </Link>
                <br className="" />
              </p>
              <Link to="/recruit/news/" target="_blank">
                <div className="jump_content">
                  <div className="btn-list">一覧に戻る</div>
                  <div className="circle-icon">
                    
                    <img
                      src="/assets/images/recruit/common/icon-new-right.png"
                      alt=""
                    />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
